import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#264653',
                secondary: '#2A9D8F',
                accent: '#E9C46A',
                error: '#E76F51',
                info: '#8AB17D',
                success: '#4CAF50',
                warning: '#F4A261',
            },
        },
    },
});
