import Vue from "vue";
import Router from "vue-router";
import Login from "../components/Login";
import Whiskies from "../components/Whiskies";
import Shared from "../components/Shared";
import store from "../store.js";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/",
      name: "home",
      redirect: "/whiskies"
    },
    {
      path: "/whiskies",
      name: "whiskies",
      component: Whiskies,
      beforeEnter: requireAuth
    },
    {
      path: "/user/:id/whiskies",
      name: "sharedBy",
      component: Whiskies,
      props: route => ({ sharedFrom: route.params.id }),
      beforeEnter: requireAuth
    },
    {
      path: "/shared",
      name: "sharedList",
      component: Shared,
      beforeEnter: requireAuth
    }
  ]
});

/*
    This will cehck to see if the user is authenticated or not.
*/
function requireAuth(to, from, next) {
  /*
      Determines where we should send the user.
    */
  function proceed() {
    /*
          If the user has been loaded determine where we should
          send the user.
        */
    if (store.getters.isUserLoaded()) {
      /*
              If the user is not empty, that means there's a user
              authenticated we allow them to continue. Otherwise, we
              send the user back to the home page.
            */
      if (store.getters.user.loggedIn) {
        next();
      } else {
        next("/login");
      }
    }
  }
  /*
      Confirms the user has been loaded
    */
  if (!store.getters.isUserLoaded()) {
    store.watch(store.getters.isUserLoaded, function() {
      if (store.getters.isUserLoaded()) {
        proceed();
      }
    });
  } else {
    proceed();
  }
}

export default router;
