import Vue from 'vue'
import App from './App.vue'
import {firestorePlugin} from 'vuefire'
import firebase from 'firebase/app'
import 'firebase/firestore'
import vuetify from './plugins/vuetify';
import './registerServiceWorker'
import router from "./routes/index";
import store from "./store";

const serialize = (snapshot) => {
    // snapshot.data() DOES NOT contain the `id` of the document. By
    // default, Vuefire adds it as a non enumerable property named id.
    // This allows to easily create copies when updating documents, as using
    // the spread operator won't copy it
    return Object.defineProperty(snapshot.data(), 'id', { value: snapshot.id })
}

export const db = firebase.initializeApp({
    projectId: 'whisky-11503',
    apiKey: 'AIzaSyA3rL6sKqkga7frdxxr__kxzbwvdoVehdM',
    authDomain: 'whisky-11503.firebaseapp.com'
}).firestore();

Vue.use(firestorePlugin, { serialize })

firebase.auth().onAuthStateChanged(user => {
    store.dispatch("fetchUser", user);
});

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
