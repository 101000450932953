<template>
  <v-container fluid fill-height class="flex-column align-stretch">
    <v-speed-dial v-if="!sharedFrom" v-model="fab" fixed bottom right>
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark small color="indigo" @click="creation = true">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn fab dark small color="grey"  @click="sharing = true">
        <v-icon>mdi-share-variant</v-icon>
      </v-btn>
    </v-speed-dial>

    <v-data-iterator fill-height
                     :items="whiskies"
                     item-key="id"
                     :items-per-page="15"
                     :search="search"
                     :sort-desc="sortDesc"
                     class="flex-grow-1 d-flex flex-column"
    >
      <template v-slot:header>
        <v-toolbar color="secondary" class="mb-6 flex-grow-0">
          <v-text-field
              v-model="search"
              clearable
              hide-details
              prepend-inner-icon="mdi-magnify"
              label="Search"
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:default="{ items }">
        <v-flex class="flex-grow-1">
          <v-row class="d-flex">
            <v-col class="d-flex flex-grow-1"
                   v-for="whisky in items"
                   :key="whisky.id"
                   cols="12"
                   sm="6"
                   md="4"
                   lg="3"
            >
              <WhiskyCard class="flex-grow-1"
                          :whisky="whisky"
                          :readonly="!!sharedFrom"
                          v-on:create-tasting="showTastingCreation(whisky)"
              ></WhiskyCard>
            </v-col>
          </v-row>
        </v-flex>
      </template>
    </v-data-iterator>

    <v-dialog v-model="creation" max-width="400px">
      <v-form v-on:submit.prevent="create()">
        <v-card v-if="creation">
          <v-card-text>
            <v-text-field
                v-model="creationId"
                autofocus
                label="WhiskyBase ID"
                type="number"
            ></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn text color="primary" @click="create()">
              Add
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="sharing" max-width="400px">
      <v-form v-on:submit.prevent="share()">
        <v-card v-if="sharing">
          <v-card-text>
            <v-text-field
                v-model="shareEmail"
                autofocus
                label="Email"
            ></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn text color="primary" @click="share()">
              Share
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog max-width="90%"
              v-model="tastingCreation"
    >
      <v-form v-on:submit.prevent="createTasting()">
        <v-card v-if="tastingCreation">
          <v-card-text>
            <v-text-field
                v-model="whisky.name"
                readonly
                label="Whisky"
            ></v-text-field>
            <span class="caption mr-2"> ({{ whisky.tasting.rating }}) </span>
            <v-rating
                v-model="whisky.tasting.rating"
                color="yellow accent-4"
                length="10"
                half-increments
                hover
                size="18"
                :readonly="!!sharedFrom"
            ></v-rating>
            <v-textarea
                v-model="whisky.tasting.description"
                filled
                label="Description"
                auto-grow
                :readonly="!!sharedFrom"
            ></v-textarea>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn text color="primary" @click="createTasting()" v-if="!sharedFrom">
              Add
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import {db} from "../main";
import axios from "axios";
import WhiskyCard from "./WhiskyCard";
import {mapGetters} from "vuex";
import firebase from 'firebase/app';

export default {
  name: "Whiskies",
  components: {
    WhiskyCard
  },
  props: ["sharedFrom"],
  computed: {
    ...mapGetters({user: "user"})
  },
  data() {
    return {
      fab: false,
      creation: false,
      creationId: undefined,
      sharing: false,
      shareEmail: undefined,
      tastingCreation: false,
      whisky: undefined,
      whiskies: [],
      search: "",
      sortBy: "",
      sortDesc: true
    };
  },
  firestore() {
    let userId = this.sharedFrom || this.user.data.id;
    return {
      whiskies: db.collection("users").doc(userId).collection("whiskies")
    };
  },
  methods: {
    create: async function () {
      const token = await firebase.auth().currentUser.getIdToken()
      const response = await axios.get(
          "https://europe-west1-whisky-11503.cloudfunctions.net/api/wb/" +
          this.creationId,
          {headers:{
              'Authorization': 'Bearer ' + token
            }}
      );
      const result = response.data;
      await db
          .collection("users").doc(this.user.data.id)
          .collection("whiskies").doc(result.whiskybase_id)
          .set({
            id: parseInt(result.whiskybase_id),
            name: result.name,
            distillery: result.distillery
          });
      this.creation = false;
      this.creationId = undefined;
    },
    showTastingCreation: async function (whisky) {
      this.whisky = {tasting: {rating: 5, description: ''}, ...whisky};
      this.whisky.id = whisky.id;
      this.tastingCreation = true;
    },
    createTasting: async function () {
      await db
          .collection("users").doc(this.user.data.id)
          .collection("whiskies").doc(this.whisky.id)
          .update(this.whisky);
      this.tastingCreation = false;
      this.whisky = undefined;
    },
    share: async function() {
      const token = await firebase.auth().currentUser.getIdToken()
      await axios.post(
          "https://europe-west1-whisky-11503.cloudfunctions.net/api/share",
          {to:this.shareEmail},
          {headers:{
            'Authorization': 'Bearer ' + token
            }}
      );
      this.sharing = false;
    }
  }
};
</script>
