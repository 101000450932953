<template>
  <div>
    <v-tabs v-model="selectedShare">
      <v-tab v-for="share in userFromDb.shared_from" :key="share.id">{{
        share.username
      }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedShare">
      <v-tab-item v-for="share in userFromDb.shared_from" :key="share.id">
        <v-card flat>
          <Whiskies :sharedFrom="share.id" />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { db } from "../main";
import { mapGetters } from "vuex";
import Whiskies from "./Whiskies";

export default {
  name: "Shared",
  components: {
    Whiskies
  },
  computed: {
    ...mapGetters({ user: "user" })
  },
  data() {
    return {
      userFromDb: {},
      selectedShare: undefined
    };
  },
  firestore() {
    const userId = this.user.data.id;
    return {
      userFromDb: db.collection("users").doc(userId)
    };
  },
  methods: {}
};
</script>
