<template>
  <v-card
    elevation="2"
    outlined
    width="300"
    class="flex-grow-1 d-flex flex-column"
  >
    <v-card-title>{{ whisky.distillery || "---" }}</v-card-title>
    <v-card-text class="flex-grow-1"
      ><a
        target="_blank"
        :href="`https://www.whiskybase.com/whiskies/whisky/${whisky.id}`"
        >{{ whisky.name }}</a
      >
      <v-rating
        v-if="whisky.tasting"
        v-model="whisky.tasting.rating"
        color="yellow accent-4"
        length="10"
        readonly
        size="10"
      ></v-rating>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn icon small color="success" @click="$emit('create-tasting')">
        <v-icon>
          mdi-pencil
        </v-icon>
      </v-btn>
      <v-btn icon small color="error" @click="del(whisky.id)" v-if="!readonly">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { db } from "../main";
import { mapGetters } from "vuex";

export default {
  name: "WhiskyCard",
  props: { whisky: {}, readonly: { default: true } },
  computed: {
    ...mapGetters({ user: "user" })
  },
  methods: {
    del: async function(id) {
      await db
        .collection("users")
        .doc(this.user.data.id)
        .collection("whiskies")
        .doc(id)
        .delete();
    }
  }
};
</script>
