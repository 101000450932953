<template>
<!--  <v-parallax-->
<!--      src="@/assets/whisky-glasses.png">-->
    <v-container
        fluid fill-height
        class="d-flex flex-column pa-9 align-center justify-space-around">
<!--      <v-img src="@/assets/home.png" class="rounded-circle" small max-width="200" max-height="200"></v-img>-->
        <v-btn
            class="align-self-center"
            x-large
            dark
            color="primary"
            depressed
            @click="login()">
          Login
        </v-btn>
    </v-container>
<!--  </v-parallax>-->
</template>

<script>
import firebase from "firebase";
import store from "../store";
import {db} from "../main";

export default {
  methods: {
    login() {
      store.state.userLoaded = false;
      firebase
          .auth()
          .signInWithPopup(new firebase.auth.GoogleAuthProvider())
          .then((res) => {
            db.collection("users").doc(res.user.uid).set({username: res.user.displayName}, {merge: true});
            this.$router.replace({name: "whiskies"});
          })
          .catch(err => {
            console.error(err.message);
          });
    }
  }
};
</script>

<style scoped>
.container {
  background-image: url(../assets/whisky-glasses.png);
  background-size: cover;
  background-position: center;

}
</style>